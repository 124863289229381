<template>
  <ol class="c-stepper">
    <li v-for="(step, index) in steps"
        :key="step"
        @click="backto(index)"
        :class="['c-stepper__item', {'activ': activated(index)}]"
    >
      <h3 class="c-stepper__title">{{ step }}</h3>
    </li>
  </ol>
</template>

<script>
export default {
  props: ['steps', 'currentStep'],
  data() {
    return {
    }
  },
  methods: {
    activated(index) {
      return index < this.currentStep
    },
    backto(index) {
      let bool = !this.activated(index)
      if(bool) {
        this.$emit('change-step', index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

.activ {
  &::before {
    background-color: $fancy !important;
    background-image: url(../assets/check.png) !important;
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
    content: '' !important;
    cursor: pointer;
    transition: apparition 1s ease;
    @keyframes apparition {
      from {
        transform: scaleX(0);
      }
      to {
        transform: scaleX(1);
      }
    }
  }
  &::after {
    background-color: $fancy !important;
  }
}

.c-stepper {
  display: flex;
  flex-wrap: wrap;
  counter-reset: compteListe;

  .c-stepper__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;

    &::before {
      counter-increment: compteListe 1;
      content: counter(compteListe);
      --size: 50px;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $fontsize;
      font-weight: bolder;
      width: var(--size);
      height: var(--size);
      border-radius: 100%;
      margin: 1rem auto 0;
      background-color: $grey;
    }

    &:not(:first-child)::after {
      content: "";
      position: relative;
      top: 40px;
      right: calc(50% - 25px + 8px);
      width: calc(100% - 50px - 8px - 8px);
      height: 3px;
      background-color: $grey;
      order: -1;
    }

    .c-stepper__title {
      font-size: $fontsize;
    }
  }
}
</style>