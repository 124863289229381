<template>
  <div class="PaymentValide">
    <h1>Votre commande a été enregistrée avec succès <ion-icon name="checkmark-circle-outline"></ion-icon></h1>
    <button @click="redirectPage('tarif')">Retour au site</button>

    <div class="infoBanque">
      <h2>Informations Bancaires</h2>
      <p>
        Votre commande ne sera traitée à réception du paiement. Les coordonnées bancaires vous seront envoyées par mail après validation de votre commande.
      </p>
      <p>
        Sinon, contactez-nous au 07 71 70 79 22.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentValide',
  data() {
    return {
    }
  },
  components: {
  },
  methods: {
    redirectPage(page) {
      window.location.replace("/" + page);
    }
  },
  created() {
    const api = require('../../api')
    api.checkCookie()
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: row wrap;
  ion-icon {
    margin-left: 20px;
    font-size: 50px;
  }
}

.PaymentValide {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 100vh;
  h1 {
    margin: 50px auto;
    text-align: center;
  }
}

.infoBanque {
  text-align: center;
  margin: 70px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $blanchatre;
  border-radius: 20px;
  padding: 20px;
  width: 50%;
  min-width: 300px;
}

button {
  background-color: $fancy;
  color: $blanchatre;
}
</style>