<template>
  <div class="payment">
    <h1>Récapitulatif</h1>
    <div class="recap">
      <p>
        <span>Montant HT</span>
        <span>{{ montantHT }} €</span>
      </p>
      <p v-if="isCorectCode">
        <span>Réduction</span>
        <span>{{ reduc.code }}</span>
        <span>{{ complementReduc }}</span>
      </p>
      <p>
        <span>Port</span>
        <span>{{ port }}</span>
      </p>
      <p>
        <span>Total HT</span>
        <span>{{ TotalHT }} €</span>
      </p>
      <p>
        <span>Montant TVA</span>
        <span>{{ MontantTVA }} €</span>
      </p>
      <p>
        <span>Prix TTC</span>
        <span>{{ TotalTTC }} €</span>
      </p>
      <p>
        <span>Quantité</span>
        <span>{{ detail.quantite }}</span>
      </p>
    </div>
    <div class="col">
      <label for>Code-Promo</label>
      <input
          @change="testValue()"
          :class="{ 'isCorrect': isCorectCode }"
          placeholder="CODE PROMO"
          v-model="reduc.code"
          type="text"
      />
    </div>

    <div class="methode-payment">
      <div>
        <input
            type="radio"
            v-model="payment"
            value="payment-CB"
            name="methode-Payment"
            id="carte-id"
        />
        <label for="carte-id">
          <span>Paiement par Carte 3Dsecure</span>
          <ion-icon name="card"></ion-icon>
        </label>
      </div>
      <div>
        <input v-model="payment" type="radio" name="methode-Payment" value="virement" id="virement" />
        <label for="virement">
          <span>Paiement par Virement</span>
          <ion-icon name="swap-horizontal"></ion-icon>
        </label>
      </div>
    </div>
    <div class="cgv">
      <input v-model="CGV" type="checkbox" name="CGV" id="CGV_OK" />
      <label for="CGV_OK">
        <span>J'ai lu et accepté les <a href="/CGV" target="_blank">Conditions Générales de vente</a></span>
      </label>
    </div>
    <div v-if="showInfoBanque" class="infoBanque">
      <h1>Informations Bancaires</h1>
      <p>
        Votre commande ne sera traitée à réception du paiement. Les coordonnées bancaires vous seront envoyées par mail après validation de votre commande.
      </p>
      <p>
        Sinon, contactez-nous au 07 71 70 79 22.
      </p>
    </div>
    <div class="button">
      <button @click="Back">Retour</button>
      <button :disabled="!isCorrect" @click="Next">Valider la commande</button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['detail'],
  data() {
    return {
      reduc: {
        code: this.detail.reduct.code,
        montant: this.detail.reduct.montant,
        pourcent: this.detail.reduct.pourcent,
        min: 1
      },
      codeState: false,
      payment: '',
      CGV: false
    }
  },
  methods: {
    Back() {
      this.$emit('GoBack')
    },
    Next() {
      let data = {
        HT: Number(this.montantHT),
        TTC: Number(this.TotalTTC),
        TVA: Number(this.MontantTVA),
        reduct: this.reduc,
        payment: this.payment,
        status: 'à traiter'
      }
      this.$emit('Validation', data)
    },
    testValue() {
      this.execApi()
    },
    execApi() {
      const api = require('../../api')
      let auth = api.getCookie('authentification')

      let CalcReduc = {
        code: '',
        montant: 0,
        pourcent: 0,
        min: 1
      }
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + auth);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("code", this.reduc.code);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://www.aventbirthday-pro.fr/api/codepromo/test", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (!result.error) {
              if (result.min <= this.detail.quantite) {
                CalcReduc = {
                  code: result.code,
                  montant: result.montant,
                  pourcent: result.pourcent,
                  min: result.min
                }
                this.reduc = CalcReduc
                this.codeState = true
              } else {
                this.reduc = CalcReduc
                this.codeState = false
              }
            } else {
              this.reduc = CalcReduc
              this.codeState = false
            }
          })
    }
  },
  computed: {
    isCorrect() {
      return (this.payment == 'virement' || this.payment == 'payment-CB') && this.CGV
    },
    isCorectCode() {
      return this.codeState
    },
    showInfoBanque() {
      return this.payment == 'virement'
    },
    TotalHT() {
      let value = (((this.montantHT * (1 - (this.reduc.pourcent / 100))) - this.reduc.montant) + this.detail.carton.port).toFixed(3)
      return (value < 0) ? 0 : value
    },
    MontantTVA() {
      return (this.TotalHT * 0.2).toFixed(2)
    },
    TotalTTC() {
      return (Number(this.MontantTVA) + Number(this.TotalHT)).toFixed(2)
    },
    complementReduc() {
      return (this.reduc.pourcent > 0) ? this.reduc.pourcent + "%" : this.reduc.montant + "€"
    },
    montantHT() {
      return (this.detail.HT).toFixed(2)
    },
    port() {
      return (this.detail.carton.port == 0) ? "Inclus" : this.detail.carton.port + " €"
    }
  },
  created() {
    const api = require('../../api')
    api.checkCookie()
    this.codeState = !(this.reduc.code === '')
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

.infoBanque {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 20px auto;
}

.methode-payment {
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  div {
    font-size: $fontsize;
    font-weight: bolder;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 80%;
    }
  }
}

.cgv {
  font-size: $fontsize;
  text-align: center;
  font-weight: bolder;
  margin: 20px auto;
  line-height: 1.5em;
  label {
    margin-left: 20px;
  }
}
.payment {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $blanchatre;
  border-radius: 20px;
  margin: 60px auto;

  h1 {
    margin: 30px auto;
  }
}

.recap {
  margin: 20px auto;
  width: 50%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 10px;

  label {
    margin: 5px 0;
    font-weight: bolder;
  }

  input {
    padding: 7px;
    border-radius: 20px;
    border: solid $grey 1px;
    font-size: $fontsize;
    color: $fontcolor;
    outline: none;

    &::placeholder {
      color: $grey;
      font-weight: bold;
    }

    &:focus-visible {
      border: solid 2px $focuscolor;
    }
  }
}

a {
  color: $fancy;
}



.col .isCorrect {
  border: solid $fancy 2px;
}

.button {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 50px auto;
  justify-content: space-evenly;

  & > button:first-child {
    background-color: $grey;
    color: $fontcolor;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1300px) {
  .payment {
    width: 70%;
  }
  .recap {
    width: 70%;
  }
}

@media (max-width: 850px) {
  .payment {
    width: 95%;
  }
  .recap {
    width: 90%;
  }
}
</style>