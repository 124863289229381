<template>
  <div id="nav" v-if="!checkURL">
    <router-link to="/tarif"><img src="./assets/logo.png" alt="Aventbirthday logo"></router-link>
    <router-link to="/tarif">Tarif</router-link>
    <router-link to="/question-reponse">FAQ</router-link>
    <router-link to="/commander">Commander</router-link>
  </div>
  <router-view/>
  <footer v-if="!checkURL">
    <div class="border" id="borderone">
      <h3>Nous contacter</h3>
      <p>Mail: contact@aventbirthday-pro.fr <br>
        <br> 
        Téléphone: 07 71 70 79 22
      </p>
    </div>
    <div class="border" id="bordertow">
      <img src="footer/SLA-logo.png" alt="" id="sla">
      <img src="footer/space-logo.png" alt="" id="space">
    </div>
    <div>
      <p><a href="CGV-Aventbirthday-pro.pdf" target="_blanck1">Conditions Générales de Ventes</a> <br> <br>
      <a href="Mentions-légales.pdf" target="_blanck3">Mentions légales</a> <br><br>
          <a href="copyright-aventbirthday.pdf" target="_blanck2">Copyright</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed:{
    checkURL(){
      let urlLocal = document.location.href.replace('https://','')
      let tableUrl = urlLocal.split('/');
      return tableUrl[1]==='';
    }
  },
  methods:{
    // redirectPage(page){
    //   window.location.replace("/"+page);
    // }
  },
  created() {
    // switch (document.location.href.replace('https://','').split('/')[1]) {
    //   case 'tarif':
    //   case 'paymentValide':
    //   case 'notvalide':
    //   case 'question-reponse':
    //   case 'commander':
    //     break;
    //   case 'admin':
    //     break
    //   case '':
    //     break
    //   default:
    //     window.location.replace("/");
    //     break;
    // }
  },
}
</script>


<style lang="scss">
@import "../scss/_variables.scss";

* {
  text-decoration: none;
  padding: 0;
  margin: 0;
  font-family: $basicfont;
  color: $fontcolor;
  list-style: none;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

h1 {
  font-size: $fontsizeH1;
}

h2 {
  font-size: $fontsizeH2;
}

p {
  font-size: $fontsize;
  text-align: center;
  font-weight: bolder;
  margin: 20px auto;
  line-height: 1.5em;
}

.floating-button {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 20px;
  font-size: 30px;
  background-color: $fancy;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 100%;

  ion-icon {
    color: $blanchatre;
  }
}

.link {
  color: $fancy;
}

.button-link {
  outline: none;
  padding: 15px 25px;
  font-size: 25px;
  border: none;
  border-radius: 20px;
  font-weight: bolder;
  transition: opacity 0.6s ease;
  background-color: $fancy;
  color: $blanchatre;
  vertical-align: baseline;
  cursor: pointer;

  &:not(:disabled):hover {
    transition: opacity 0.3s ease;
    opacity: 0.8;
  }

  &:focus-visible {
    border: $focuscolor solid 2px;
  }

  &:disabled {
    &:hover {
      animation: shake 0.4s forwards;

      @keyframes shake {
        0% {
          transform: translateX(0px);
        }
        20% {
          transform: translateX(-10px);
        }
        40% {
          transform: translateX(10px);
        }
        60% {
          transform: translateX(-10px);
        }
        80% {
          transform: translateX(10px);
        }
        100% {
          transform: translateX(0px);
        }
      }
    }
  }
}

button {
  outline: none;
  padding: 15px 25px;
  font-size: 25px;
  border: none;
  border-radius: 20px;
  font-weight: bolder;
  transition: opacity 0.6s ease;
  background-color: $fancy;
  color: $blanchatre;
  vertical-align: baseline;
  cursor: pointer;

  ion-icon {
    color: white;
    font-size: $fontsize;
  }

  &:not(:disabled):hover {
    transition: opacity 0.3s ease;
    opacity: 0.8;
  }

  &:focus-visible {
    border: $focuscolor solid 2px;
  }

  &:disabled {
    &:hover {
      animation: shake 0.4s forwards;

      @keyframes shake {
        0% {
          transform: translateX(0px);
        }
        20% {
          transform: translateX(-10px);
        }
        40% {
          transform: translateX(10px);
        }
        60% {
          transform: translateX(-10px);
        }
        80% {
          transform: translateX(10px);
        }
        100% {
          transform: translateX(0px);
        }
      }
    }
  }
}

body {
  background-color: $background;
}

.is-visible {
  opacity: 1 !important;
  transition: opacity 0.5s ease;
}

.hide {
  display: none;
}

#nav {
  position: sticky;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: $fancy;
  padding: 10px 0;

  a {
    font-weight: bold;
    color: $fontcolor;
    font-size: $fontsize;
    padding: 15px 30px;
    border-radius: 30px;

    &:not(:first-child) {
      color: $blanchatre;
      background-color: $fancy;
    }

    &.router-link-exact-active:not(:first-child) {
      color: $fancy;
      background-color: $blanchatre;
    }
  }

  img {
    height: 100px;
  }
}

footer {
  padding: 3vh 0;
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  min-height: 20vh;
  width: 100%;
  margin: 0 auto;
  background-color: $fancy;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h3 {
      font-size: $fontsizeH2;
      color: white;
    }

    p {
      text-align: left;
      color: white;

      a {
        color: white;
        cursor: pointer;
        transition: all ease .5s;

        &:hover {
          transition: all ease .2s;
          opacity: .8;
        }
      }
    }

    img {
      width: 250px;
      margin: 0 auto;
    }
  }

  .border-two {
    padding-right: 7%;
    border-right: 2px solid white;
  }
}

@media (max-width: 1000px) {
  #border-two {
    border: none;
  }
}

@media (max-width: 867px) {
  #border-two {
    border: none;
  }
}
</style>