<template>
  <div class="tarif">
    <h1>Bienvenue sur le site dédié aux professionnels du loisir.</h1>
    <p>Vous trouverez ici une foire aux questions, les tarifs ainsi que la procédure pour passer commande.</p>
    <div class="laGamme">
      <img src="gamme-arc.png" alt="">
      <img src="event/promo.png" alt="">
    </div>
    <h2>Grille tarifaire</h2>
    <GrillePrix />
    <h2>Les réductions automatiques</h2>
    <GrilleReduction />
    <h2 id="marque-blanche">LA MARQUE BLANCHE C'EST POSSIBLE!</h2>
    <div class="marque-blanche">
      <img src="tarifs/votre-logo.png" alt />
      <ul>
        <li>Votre Logo</li>
        <li>Vos questions/réponses</li>
        <li>votre contenu</li>
        <br>
        <span>Plus d'informations <a class="link" href="/question-reponse">voir les FAQ</a></span>
      </ul>
    </div>
    <a class="button-link" href="/commander">Commander</a>
    <h2>Etapes d'une commande</h2>
    <EtapesTarifs text="Je choisis la quantité de calendriers" num="1" images="tarifs/trot.png"/>
    <EtapesTarifs text="Je compose ma commande" num="2" images="tarifs/skate-1.png"/>
    <EtapesTarifs text="Je valide ma commande par Carte ou Virement" num="3" images="tarifs/skate-2.png"/>
    <EtapesTarifs text="Je reçois ma commande en 72h!" num="4" images="tarifs/base.png"/>
    <a class="button-link" href="/commander">Commander</a>
  </div>
</template>

<script>
import EtapesTarifs from '../components/EtapesTarifs.vue'
import GrillePrix from '../components/GrillePrix.vue'
import GrilleReduction from '../components/GrilleReduction.vue'

export default {
  name: 'Tarif',
  components: {
    EtapesTarifs,
    GrillePrix,
    GrilleReduction
  },
  data() {
    return {
    }
  },
  created() {
    const api = require('../../api')
    api.checkCookie()
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

.marque-blanche {
  width: 50%;
  margin-top: 10px;
  background-color: $blanchatre;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row wrap;
  img {
    max-width: 300px;
    min-width: 200px;
    margin-left: 10px;
  }
  ul {
    margin-right: 10px;
    font-size: $fontsize2;
    li {
      padding-left: 20px;
      list-style: square inside;
    }
    span {
      padding-left: 10px;
    }
  }
}

#marque-blanche {
  margin-top: 40px;
  margin-bottom: 20px;
}

.button-link {
  margin: 50px 0;
  color: $blanchatre;
  background: $fancy;
}

.laGamme {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 70%;
  }
}

.tarif {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  margin-bottom: 100px;
  & h1 {
    margin: 50px 0;
    text-align: center;
  }
  h2 {
    margin: 50px 0;
    text-align: center;
  }
}

@media (max-width: 950px) {
  .laGamme {
    width: 95%;
    img {
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .marque-blanche {
    width: 95%;
  }
  .laGamme {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
</style>